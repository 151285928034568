<template>
  <section class="all-documents-view">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <el-row>
          <el-col :lg="2" :md="6" :sm="12">
            <el-tooltip content="Go back to contacts">
              <el-button class="back-btn" @click="goBack">
                <i class="el-icon-back">{{ getIsMobile ? "" : "Back" }}</i>
              </el-button>
            </el-tooltip>
          </el-col>
          <el-col :lg="12" :md="6" :sm="12" :xs="24">
            <el-col :lg="18" :md="6" :sm="12">
              <h3
                class="title fw-normal text-dark mb-0 ls-05"
                v-if="
                  this.$route &&
                  this.$route.params &&
                  this.$route.params.name &&
                  this.$route.params.lastname
                "
              >
                {{
                  this.$route.params.name + " " + this.$route.params.lastname
                }}
                Documents
              </h3>
            </el-col>
            <el-col :lg="4" :md="12" :sm="24">
              <el-dropdown
                class="import-dropdown-btn"
                v-if="this.selected_contact_documents.length"
              >
                <el-button size="mini" class="el-dropdown-link">
                  Delete
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <a
                    v-if="
                      (this.selectedStatus &&
                        this.selectedStatus != 'archived') ||
                      !this.selectedStatus
                    "
                    @click="bulkDeleteOfSelectedContacts()"
                  >
                    <el-dropdown-item>
                      <i class="el-icon-delete"></i>
                      Delete
                    </el-dropdown-item>
                  </a>
                  <a
                    v-if="
                      this.selectedStatus && this.selectedStatus == 'archived'
                    "
                    @click="bulkpermanentdelete()"
                  >
                    <el-dropdown-item>
                      <i class="el-icon-delete-solid"></i>
                      <span>Permanent Delete</span>
                    </el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-col>
          <el-col :lg="4" :md="6" :sm="12" :xs="12" class="mb-1">
            <el-select
              v-model="selectedStatus"
              filterable
              clearable
              class="select select-status"
              placeholder="Select Status"
              @change="fetchUserDocuments"
            >
              <el-option
                v-for="(status, index) in statusOption"
                :key="index"
                :value="status.value"
                :label="status.name"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :lg="4" :md="6" :sm="12" :xs="12">
            <el-input
              class="search-string ml-1"
              placeholder="Search"
              v-model="search_string"
              @input="fetchUserDocuments"
              clearable
            ></el-input>
          </el-col>
        </el-row>
        <div v-loading="loading" class="vue-data-table-default">
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="userDocuments"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            @sort-change="sortChange"
            style="width: 100%"
            v-if="!getIsMobile"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="Name"
              id="name"
              min-width="240"
              fixed="left"
              prop="title"
              sortable="custom"
            >
              <template slot-scope="scope">
                <div class="icon-block">
                  <div class="icon">
                    <div class="image-file" v-if="isImage(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div
                      class="doc-file"
                      v-if="
                        !isImage(scope.row.file_type) &&
                        !isPdf(scope.row.file_type)
                      "
                    >
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="icon-text">
                    <!-- <template v-if="scope.row.title">
                      {{
                      scope.row.title
                      }}
                    </template> -->

                    <template v-if="scope.row.title">
                      <p class="doc-title" @click="onEdit(scope.row)">
                        {{ getDocumentTitle(scope.row.title) }}
                      </p>

                      <span v-if="getDocumentUsers(scope.row)">
                        <p class="doc-sub">
                          {{ getDocumentUsers(scope.row) }}
                        </p>
                      </span>
                      <!-- <span  v-if="getDocumentUsersTO(scope.row)"> -->
                      <!-- <p class="doc-sub">To : {{getDocumentUsersTO(scope.row)}}</p>
                  </span>

                  <span  v-if="!getDocumentUsersTO(scope.row) && !getDocumentUsers(scope.row)">
                 <p class="doc-sub">No Recipients </p>
                  </span> -->
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="action"
              class="action"
              align="right"
              width="180"
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <el-tooltip placeholder="top-start" content="View Document">
                    <li
                      v-if="
                        (scope.row.document_status == 'SENT' ||
                          scope.row.document_status == 'APPROVED') &&
                        !waitingForMe(scope.row)
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onEdit(scope.row)"
                      >
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip content="Sign Document">
                    <li
                      v-if="
                        (scope.row.document_status == 'SENT' ||
                          scope.row.document_status == 'APPROVED') &&
                        waitingForMe(scope.row)
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onEdit(scope.row)"
                      >
                        <i class="el-icon-edit-outline"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip content="Download Document">
                    <li v-if="scope.row.document_status == 'COMPLETED'">
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onDownload(scope.row)"
                      >
                        <i class="el-icon-download"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <!-- <li>
                    <el-button plain type="danger"  @click="onDelete(scope.row)"  size="mini" class="px-2">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </li> -->
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="
                            checkPermissions('renameDocument') &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.created_by === authid
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'Rename'
                            )
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-edit-outline"></i>
                            Rename</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED' &&
                            scope.row.document_status != 'DRAFT' &&
                            scope.row.document_status != 'DECLINED'
                          "
                          @click="ResendDocument(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-s-promotion"></i> Resend Document
                          </el-dropdown-item>
                        </a>
                        <a
                          @click="onDownload(scope.row)"
                          v-if="
                            checkPermissions('downloadDocument') &&
                            scope.row.document_status == 'COMPLETED'
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-download"></i>
                            Download</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'duplicate'
                            )
                          "
                          v-if="
                            checkPermissions('cloneDocument') &&
                            scope.row.document_status == 'DRAFT'
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-copy-document"></i>
                            Duplicate</el-dropdown-item
                          >
                        </a>
                        <!--<a
                         v-if="
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'EXPIRED' &&
                             scope.row.document_status != 'DECLINED' &&
                            scope.row.document_status != 'ARCHIVED'
                          "
                          @click="
                            handleSendDocumentToUsers(scope.row, {
                              openSendDocumentsModal: true,
                            })
                          "
                        >
                        <el-dropdown-item>Send Document</el-dropdown-item>
                        </a>-->
                        <a
                          @click="alertCorrectedDoc(scope.row._id)"
                          v-if="
                            checkPermissions('voidDocument') &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'DRAFT' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.created_by === authid
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-remove-outline"></i> Void
                            Document</el-dropdown-item
                          >
                        </a>

                        <a
                          @click="onDelete(scope.row)"
                          v-if="
                            checkPermissions('deleteDocument') &&
                            ((scope.row.created_by === authid &&
                              scope.row.document_status != 'ARCHIVED') ||
                              (scope.row.document_status == 'VOIDED' &&
                                scope.row.document_status == 'DECLINED' &&
                                scope.row.document_status == 'COMPLETED'))
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-delete"></i>
                            Delete</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="archiveDocumentundo(scope.row._id)"
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-refresh-left"></i> Undo
                            Delete</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="permanentDocument(scope.row._id)"
                          v-if="
                            checkPermissions('deleteDocument') &&
                            scope.row.created_by === authid &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-delete-solid"></i> Permanent
                            Delete</el-dropdown-item
                          >
                        </a>

                        <!-- <el-dropdown-item
                          v-if="scope.row.document_status != 'VOIDED'"
                          >Copy Link to share document</el-dropdown-item
                        >-->

                        <a
                          @click="settings(scope.row._id)"
                          v-if="
                            checkPermissions('documentSettings') &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'EXPIRED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.created_by === authid
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-setting"></i> Settings
                          </el-dropdown-item>
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>

            <el-table-column
              prop="document_tracking_status.description"
              label="Current Status"
              min-width="220"
            >
              <template slot-scope="scope">
                <el-progress
                  :percentage="getSignatureStatus(scope.row)"
                  :format="format"
                  v-if="scope.row.document_status == 'SENT'"
                >
                </el-progress>

                <p class="doc-subs" v-if="scope.row.document_status == 'SENT'">
                  {{ getdoument(scope.row) }}
                </p>

                <el-popover
                  placement="top-start"
                  :title="getWaitingUser(scope.row)"
                  width="200"
                  trigger="hover"
                  v-if="scope.row.document_status == 'SENT'"
                >
                  <span style="font-size: 10px">{{
                    getCurrentRequestedUser(scope.row)
                  }}</span>
                  <p slot="reference" class="text-secondary-sent">
                    {{
                      scope.row.document_tracking_status &&
                      scope.row.document_tracking_status.description
                        ? scope.row.document_tracking_status.description
                        : ""
                    }}
                  </p>
                </el-popover>
                <p
                  slot="reference"
                  class="text-secondary"
                  v-if="scope.row.document_status != 'SENT'"
                >
                  {{
                    scope.row.document_tracking_status &&
                    scope.row.document_tracking_status.description
                      ? scope.row.document_tracking_status.description
                      : ""
                  }}
                </p>
              </template>
            </el-table-column>

            <el-table-column
              label="Status"
              id="category"
              width="170"
              prop="document_status"
              sortable="custom"
            >
              <template slot-scope="scope" id="category">
                <span
                  v-if="scope && scope.row && scope.row.document_status"
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >{{ scope.row.document_status }}</span
                >
              </template>
            </el-table-column>
            <!-- <el-table-column label="Status" id="category" width="250">
							<template slot-scope="scope" id="category">
								<span :class="scope.row.status.toLowerCase() + ' circle'">
									{{ scope.row.status }}
								</span>
							</template>
            </el-table-column>-->
            <el-table-column
              label="Last Modified Time"
              sortable="custom"
              min-width="200"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.updated_at">
                  <span class="fs-8 d-inline-block pr-2 text-muted">{{
                    scope.row.updated_at | globalDateTimeFormat
                  }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              label="Created Time"
              sortable="custom"
              min-width="250"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.updated_at">
                  <span class="fs-8 d-inline-block pr-2 text-muted">{{
                    scope.row.created_at | globalDateTimeFormat
                  }}</span>
                </template>
              </template>
            </el-table-column>
          </data-tables-server>

          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="userDocuments"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            v-if="getIsMobile"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Current Status:</div>
                  <div class="expand-column-item-content">
                    <el-progress
                      :percentage="getSignatureStatus(scope.row)"
                      :format="format"
                      v-if="scope.row.document_status == 'SENT'"
                    >
                    </el-progress>

                    <p
                      class="doc-subs"
                      v-if="scope.row.document_status == 'SENT'"
                    >
                      {{ getdoument(scope.row) }}
                    </p>

                    <el-popover
                      placement="top-start"
                      :title="getWaitingUser(scope.row)"
                      width="200"
                      trigger="hover"
                      v-if="scope.row.document_status == 'SENT'"
                    >
                      <span style="font-size: 10px">{{
                        getCurrentRequestedUser(scope.row)
                      }}</span>
                      <p slot="reference" class="text-secondary-sent">
                        {{
                          scope.row.document_tracking_status &&
                          scope.row.document_tracking_status.description
                            ? scope.row.document_tracking_status.description
                            : ""
                        }}
                      </p>
                    </el-popover>
                    <p
                      slot="reference"
                      class="text-secondary"
                      v-if="scope.row.document_status != 'SENT'"
                    >
                      {{
                        scope.row.document_tracking_status &&
                        scope.row.document_tracking_status.description
                          ? scope.row.document_tracking_status.description
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Status:</div>
                  <div class="expand-column-item-content">
                    <span
                      v-if="
                        scope.row &&
                        scope.row.document_status &&
                        scope.row.document_status != 'null'
                      "
                      :class="
                        scope.row.document_status.toLowerCase() + ' circle'
                      "
                      >{{ scope.row.document_status }}</span
                    >
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">
                    Last modified Time:
                  </div>
                  <div class="expand-column-item-content">
                    <template v-if="scope.row.updated_at">
                      <span class="fs-8 d-inline-block pr-2 text-muted">{{
                        scope.row.updated_at | globalDateTimeFormat
                      }}</span>
                    </template>
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Created Time:</div>
                  <div class="expand-column-item-content">
                    <template v-if="scope.row.updated_at">
                      <span class="fs-8 d-inline-block pr-2 text-muted">{{
                        scope.row.created_at | globalDateTimeFormat
                      }}</span>
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Name"
              id="name"
              min-width="220"
              prop="title"
              sortable
              :sort-method="titleSort"
              fixed
            >
              <template slot-scope="scope">
                <div class="icon-block">
                  <div class="icon">
                    <div class="image-file" v-if="isImage(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div
                      class="doc-file"
                      v-if="
                        !isImage(scope.row.file_type) &&
                        !isPdf(scope.row.file_type)
                      "
                    >
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="icon-text">
                    <!-- <template v-if="scope.row.title">
                      {{
                      scope.row.title
                      }}
                    </template> -->

                    <template v-if="scope.row.title">
                      <p class="doc-title">
                        {{ getDocumentTitle(scope.row.title) }}
                      </p>

                      <span v-if="getDocumentUsers(scope.row)">
                        <p class="doc-sub">
                          {{ getDocumentUsers(scope.row) }}
                        </p>
                      </span>
                      <!-- <span  v-if="getDocumentUsersTO(scope.row)"> -->
                      <!-- <p class="doc-sub">To : {{getDocumentUsersTO(scope.row)}}</p>
                  </span>

                  <span  v-if="!getDocumentUsersTO(scope.row) && !getDocumentUsers(scope.row)">
                 <p class="doc-sub">No Recipients </p>
                  </span> -->
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              class="action"
              align="right"
              width="150"
              fixed
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <li
                    v-if="
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVED') &&
                      !waitingForMe(scope.row)
                    "
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button>
                  </li>
                  <el-tooltip content="Sign Document">
                    <li
                      v-if="
                        (scope.row.document_status == 'SENT' ||
                          scope.row.document_status == 'APPROVED') &&
                        waitingForMe(scope.row)
                      "
                    >
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onEdit(scope.row)"
                      >
                        <i class="el-icon-edit-outline"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip content="Download Document">
                    <li v-if="scope.row.document_status == 'COMPLETED'">
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onDownload(scope.row)"
                      >
                        <i class="el-icon-download"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <!-- <li>
                    <el-button plain type="danger"  @click="onDelete(scope.row)"  size="mini" class="px-2">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </li> -->
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.created_by === authid
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'Rename'
                            )
                          "
                        >
                          <el-dropdown-item>Rename</el-dropdown-item>
                        </a>
                        <a
                          @click="onDownload(scope.row)"
                          v-if="scope.row.document_status == 'COMPLETED'"
                        >
                          <el-dropdown-item>Download</el-dropdown-item>
                        </a>
                        <a
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'duplicate'
                            )
                          "
                          v-if="scope.row.document_status == 'DRAFT'"
                        >
                          <el-dropdown-item>Duplicate</el-dropdown-item>
                        </a>
                        <!--<a
                         v-if="
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'EXPIRED' &&
                             scope.row.document_status != 'DECLINED' &&
                            scope.row.document_status != 'ARCHIVED'
                          "
                          @click="
                            handleSendDocumentToUsers(scope.row, {
                              openSendDocumentsModal: true,
                            })
                          "
                        >
                        <el-dropdown-item>Send Document</el-dropdown-item>
                        </a>-->
                        <a
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED' &&
                            scope.row.document_status != 'DRAFT' &&
                            scope.row.document_status != 'DECLINED'
                          "
                          @click="ResendDocument(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-s-promotion"></i> Resend Document
                          </el-dropdown-item>
                        </a>
                        <a
                          @click="alertCorrectedDoc(scope.row._id)"
                          v-if="
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'DRAFT' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.created_by === authid
                          "
                        >
                          <el-dropdown-item>Void Document</el-dropdown-item>
                        </a>

                        <a
                          @click="onDelete(scope.row)"
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status != 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>Delete</el-dropdown-item>
                        </a>
                        <a
                          @click="archiveDocumentundo(scope.row._id)"
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>Undo Delete</el-dropdown-item>
                        </a>

                        <a
                          @click="permanentDocument(scope.row._id)"
                          v-if="
                            scope.row.created_by === authid &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>Permanent Delete</el-dropdown-item>
                        </a>

                        <!-- <el-dropdown-item
                          v-if="scope.row.document_status != 'VOIDED'"
                          >Copy Link to share document</el-dropdown-item
                        > -->

                        <a
                          @click="settings(scope.row._id)"
                          v-if="
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'EXPIRED' &&
                            scope.row.created_by === authid
                          "
                        >
                          <el-dropdown-item>Settings </el-dropdown-item>
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
    <dialog-component
      :visible="ResendDocumentToRecipient"
      title="Document Users"
      :containerWidth="'60vw'"
      @before-close="closeResendToRecipient"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-table :data="resendDocumentUsers" style="width: 100%" border>
          <el-table-column
            prop="first_name"
            label="First Name"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="last_name"
            label="Last Name"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="Email"
            min-width="180"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="Role"
            width="130"
          ></el-table-column>
          <!-- <el-table-column
        prop="address"
        label="Address"
        min-width="180"
      ></el-table-column> -->
          <!-- <el-table-column
        prop="company_name"
        label="Company"
        width="100"
      ></el-table-column> -->
          <el-table-column prop="company_name" label="Other" width="125">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="resendDocLink(scope.row)"
                v-if="
                  scope.row.sent_status &&
                  !scope.row.document_filling_status &&
                  scope.row.type != 'SENDER' &&
                  scope.row.user_type != 'CC'
                  //isUserIsDocumentOwner &&
                  //isAccessDocument
                "
              >
                Resend</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </dialog-component>
    <el-dialog
      :title="modalData.action + ' Document'"
      :visible.sync="documentActionModal"
      width="30%"
      class="doc-modal"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <span>
        <label>Document Title :</label>
        <el-input
          type="text"
          placeholder="Document Name"
          v-model="modalData.name"
        ></el-input>
        <p
          class="error"
          v-if="
            getDuplicateDocumentErrors &&
            getDuplicateDocumentErrors.critical_error
          "
        >
          {{ getDuplicateDocumentErrors.critical_error }}
        </p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="documentActionModal = false">Cancel</el-button>
        <el-button
          :disabled="!modalData.name.length"
          type="primary"
          @click="handleDocAction"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="contactsBulkDeletePopup"
      width="28%"
      title="Warning"
      class="DialogueStyle"
      :before-close="resetContactsBulkDelete"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
      <span v-if="this.selected_contact_documents.length == 1">
        Are you sure you want to delete 1 selected document?</span
      >
      <span v-else
        >Are you sure you want to delete
        {{ this.selected_contact_documents.length }} selected documents?</span
      >
      <span slot="footer">
        <el-button type="plain" @click="resetContactsBulkDelete()"
          >Cancel</el-button
        >
        <el-button type="primary" @click="deleteContactsDocuments()"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <ConfigureSettings
      v-if="docSettingsVisible"
      :configurableDocumentId="configurableDocumentId"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
    ></ConfigureSettings>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import store from "@/store";
// import appConfig from "@/config/app";
const ConfigureSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNewTemp"
  );
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
export default {
  name: "contacts-UserDoc",
  components: {
    ConfigureSettings,
  },
  mixins: [ComanyDocumentsPresignedURLsHelper],
  data() {
    return {
      authid: "",
      docSettingsVisible: false,
      loading: false,
      total: 0,
      sortItem: {},
      selected_contact_documents: [],
      contactsBulkDeletePopup: false,
      currentPage: 1,
      pageSize: 10,
      ResendDocumentToRecipient: false,
      resendDocumentUsers: [],
      resendEmployeeDocumentId: null,
      page: 1,
      order_by: null,
      order_type: null,
      configureLoading: false,
      openSendDocumentsModal: false,
      showSendDocumentoUserSettings: false,
      // basePdfdownloadUrl: "https://esign.kodefast.com:4008",
      userDocuments: [],
      search_string: "",
      selectedStatus: "",
      statusOption: [
        { name: "To me", value: "to-me" },
        { name: "From me", value: "from-me" },
        { name: "Company Documents", value: "company-documents" },
        { name: "Sent", value: "sent" },
        { name: "Declined", value: "declined" },
        { name: "Archived", value: "archived" },
        { name: "Voided", value: "voided" },
        // { name: "Signed", value: "EMPLOYEE_SIGNED" },
        { name: "Approved", value: "approved" },
        { name: "Completed", value: "completed" },
        // { name: "Verified", value: "verified" },
        { name: "Expired", value: "expired" },
        { name: "Expiring Soon", value: "expiring-soon" },
      ],
      contactName: "",
      documents: [],
      modalData: {
        document_id: "",
        name: "",
        action: "",
      },
      documentActionModal: false,
      modalLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getUserType"]),
    ...mapGetters("documents", [
      "getAllDocuments",
      "getRenameDocumentStatus",
      "getDuplicateDocumentStatus",
      "getArchiveDocumentStatus",
      "getDuplicateDocumentErrors",
      "getResendDocumentStatus",
      "getDocumentVoidStatus",
      "getUserDocuments",
      "archiveDocuments",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),

    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL;
      // return appConfig.S3_BUCKET_URL;
      return "";
    },
  },
  async mounted() {
    await this.fetchUserDocuments();
  },
  methods: {
    closeResendToRecipient() {
      this.ResendDocumentToRecipient = false;
    },
    async ResendDocument(data) {
      console.log("data", data);
      console.log(
        " this.ResendDocumentToRecipient",
        this.ResendDocumentToRecipient
      );
      this.resendDocumentUsers = data.document_users;
      this.ResendDocumentToRecipient = true;
      this.resendEmployeeDocumentId = data._id;
    },
    async resendDocLink(contact) {
      console.log("-------------------", contact);
      try {
        let params = {
          document_id: this.resendEmployeeDocumentId,
          contact_id:
            contact && contact.contact._id
              ? contact.contact._id
              : contact.contact_id._id,
          is_new_user: false,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log(err);
      }
    },
    waitingForMe(data) {
      let document_user = data.document_users.find((user) => {
        return user.email == this.getAuthenticatedUser.email;
      });
      if (
        document_user &&
        document_user.sent_status &&
        !document_user.document_filling_status
      ) {
        return true;
      } else {
        return false;
      }
    },
    bulkDeleteOfSelectedContacts() {
      this.contactsBulkDeletePopup = true;
      console.log(this.selectedStatus);
    },
    deleteContactsDocuments() {
      try {
        this.selected_contact_documents.forEach(async (doc, index) => {
          if (
            doc &&
            this.getAuthenticatedUser &&
            doc.created_by === this.getAuthenticatedUser._id
          ) {
            await this.$store.dispatch("documents/archiveDocument", {
              document_id: doc._id,
            });
            this.contactsBulkDeletePopup = false;
            if (index == this.selected_contact_documents.length - 1) {
              this.$notify.success({
                title: "Success",
                message: "Documents deleted successfully",
              });
              await this.fetchUserDocuments();
            }
          } else {
            this.$message({
              showClose: true,
              message:
                "You can only delete the documents which are created by you.",
              type: "warning",
            });
            this.contactsBulkDeletePopup = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    bulkpermanentdelete() {
      try {
        this.selected_contact_documents.forEach(async (doc, index) => {
          if (
            doc &&
            doc.document_status == "ARCHIVED" &&
            this.getAuthenticatedUser &&
            doc.created_by === this.getAuthenticatedUser._id
          ) {
            await this.$store.dispatch("documents/parmanentdelete", {
              document_id: doc._id,
            });
            this.contactsBulkDeletePopup = false;
            if (index == this.selected_contact_documents.length - 1) {
              this.$notify.success({
                title: "Success",
                message: "Documents deleted successfully",
              });
              await this.fetchUserDocuments();
            }
          } else {
            this.$message({
              showClose: true,
              message:
                "You can only delete the documents which are created by you.",
              type: "warning",
            });
            this.contactsBulkDeletePopup = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    resetContactsBulkDelete() {
      this.contactsBulkDeletePopup = false;
    },
    handleSelectionChange(val) {
      this.selected_contact_documents = [];
      this.selected_contact_documents = val;
    },
    getWaitingUser(doc) {
      console.log(doc);
      let isFromCk =
        doc &&
        doc.configurable_document_data &&
        doc.configurable_document_data.ckeditor_fields &&
        doc.configurable_document_data.ckeditor_fields.length
          ? true
          : false;
      if (doc.enforce_signature_order) {
        let currentUser = "";
        doc.document_users.forEach((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status && isFromCk && !user.document_filling_status)
          ) {
            currentUser = user.first_name;
          }
        });
        let result = "Waiting for " + currentUser;
        return result;
      } else {
        let currentUser = "";
        doc.document_users.find((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status && isFromCk && !user.document_filling_status)
          ) {
            currentUser += user.first_name;
          }
        });
        let result = "Waiting for " + currentUser;
        return result;
      }
    },
    getCurrentRequestedUser(doc) {
      let isFromCk =
        doc &&
        doc.configurable_document_data &&
        doc.configurable_document_data.ckeditor_fields &&
        doc.configurable_document_data.ckeditor_fields.length
          ? true
          : false;

      if (doc.enforce_signature_order) {
        let sent_date = "";
        doc.document_users.forEach((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (isFromCk && user.sent_status && !user.document_filling_status)
          ) {
            sent_date = user.sent_on;
          }
        });
        const format = store.getters["auth/getDefaultDateFormat"]
          ? store.getters["auth/getDefaultDateFormat"] + " HH:mm A"
          : "MM-DD-YYYY HH:mm A";
        let result = "Sent On " + moment(sent_date).format(format);
        return result;
      } else {
        let currentUser = "";
        doc.document_users.forEach((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (isFromCk && user.sent_status && !user.document_filling_status)
          ) {
            currentUser += user.first_name;
          }
        });
        let result = currentUser;
        return result;
      }
    },
    openNameModal(id, name, action) {
      try {
        this.$store.commit("documents/setDuplicateDocumentErrors", null, {
          root: true,
        });
        this.modalData.document_id = id;
        this.modalData.name = name;
        this.modalData.action = action;
        this.documentActionModal = true;
      } catch (err) {
        console.log(err);
      }
    },
    handleDocAction() {
      if (this.modalData.action == "duplicate") {
        this.duplicateDocument();
      } else {
        this.renameDocument();
      }
    },
    goBack() {
      this.$router.go(-1);
      this.userDocuments = [];
    },
    async duplicateDocument() {
      this.modalLoading = true;
      await this.$store.dispatch("documents/duplicateDocument", this.modalData);
      this.modalLoading = false;
      if (this.getDuplicateDocumentStatus) {
        successNotification("Document duplicated successfully");
        this.documentActionModal = false;
        this.fetchUserDocuments();
      } else {
        errorNotification("Error in duplicating document");
      }
    },
    async renameDocument() {
      this.modalLoading = true;
      await this.$store.dispatch("documents/renameDocument", this.modalData);
      this.modalLoading = false;
      if (this.getRenameDocumentStatus) {
        successNotification("Document title updated successfully");
        this.documentActionModal = false;
        this.fetchUserDocuments();
      } else {
        errorNotification("Error in updating document title");
      }
    },

    getSignatureStatus(doc) {
      let isFromCk =
        doc &&
        doc.configurable_document_data &&
        doc.configurable_document_data.ckeditor_fields &&
        doc.configurable_document_data.ckeditor_fields.length
          ? true
          : false;
      let total_sign_users = 0;
      let completed_sign_users = 0;
      doc.document_users.forEach((user) => {
        if (user.fields_required || isFromCk) {
          total_sign_users++;

          if (user.document_filling_status) {
            completed_sign_users++;
          }
        }
      });
      if (total_sign_users == 0) {
        return 0;
      } else {
        this.nodoc = total_sign_users;
        this.nonsig = completed_sign_users;
        return (completed_sign_users / total_sign_users) * 100;
      }
    },
    getdoument(doc) {
      let isFromCk =
        doc &&
        doc.configurable_document_data &&
        doc.configurable_document_data.ckeditor_fields &&
        doc.configurable_document_data.ckeditor_fields.length
          ? true
          : false;
      let tousers = "";
      let total_sign_users = 0;
      let completed_sign_users = 0;
      doc.document_users.forEach((user) => {
        if (user.fields_required || isFromCk) {
          total_sign_users++;

          if (user.document_filling_status) {
            completed_sign_users++;
          }
        }
      });

      if (total_sign_users == 0) {
        tousers += completed_sign_users + "/" + total_sign_users;
        return tousers;
      } else {
        tousers += completed_sign_users + "/" + total_sign_users;
        return tousers;
      }
    },

    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    getDocumentUsers(doc) {
      let users = "";
      let last_user = doc.document_users.length - 1;
      let isDocOwner = false;
      doc.document_users.forEach((user) => {
        if (
          user.email == this.getAuthenticatedUser.email &&
          user.type == "SENDER"
        ) {
          isDocOwner = true;
        }
      });
      if (isDocOwner) {
        users += "To: ";
        doc.document_users.forEach((user, i) => {
          if (user.type == "RECEIVER") {
            let sep = ",";
            if (i == last_user) {
              sep = " ";
            }
            users += user.first_name + sep;
          } else if (doc.document_users.length == 1) {
            users += "Only me";
          }
        });
      } else {
        users += "From: ";
        doc.document_users.forEach((user) => {
          if (user.type == "SENDER") {
            users += user.first_name;
          }
        });
      }
      return users;
    },

    getDocumentUsersTO(doc) {
      let tousers = "";
      let last_user = doc.document_users.length - 1;
      doc.document_users.forEach((user, i) => {
        if (user.type === "RECEIVER") {
          let sep = ",";

          if (i == last_user) {
            sep = "  ";
          }

          if (user.first_name) {
            if (user.email != this.getAuthenticatedUser.email) {
              tousers += user.first_name + sep;
            }
          }
        }
      });
      return tousers;
    },

    getDocumentTitle(title) {
      if (title.length > 20) {
        return title.slice(0, 18) + "...";
      }
      return title;
    },
    async alertCorrectedDoc(doc_id) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(doc_id);
        });
      }
    },
    async DocumentVoid(doc_id) {
      // console.log("doc_id", doc_id);
      let document_id = doc_id;
      await this.$store.dispatch("documents/voidDocument", { document_id });
      if (this.getDocumentVoidStatus) {
        successNotification("Document Voided successfully");
        this.$emit("reloadDashboardStats", true);
        this.fetchUserDocuments();
      } else {
        errorNotification("Error in void document");
      }
    },
    async settings(_id) {
      this.docSettingsVisible = true;
      this.configurableDocumentId = _id;
    },
    async downloadAlldocuments(data) {
      this.onDownload(data);
      let allAttachments = this.attachmentsFiles(data);
      if (allAttachments && allAttachments.length) {
        let paths = allAttachments.map((el) => el.attachment_url);
        let titles = allAttachments.map((el) => el.title);
        /** MIXIN */
        await this.downloadFilesFromS3Paths(paths, titles);
      }
    },
    async downloadPdfFile(url, name) {
      await this.downloadFilesFromS3Paths([url], [name]);
    },
    async gotoMainDocument(data) {
      if (data && data.document_completed_url) {
        let url = this.basePdfdownloadUrl + data.document_completed_url;
        await this.downloadFilesFromS3Paths([url], [data.title]);
      }
    },
    async gotoAttachment(file) {
      if (file && file.attachment_url) {
        let url = this.basePdfdownloadUrl + file.attachment_url;
        await this.downloadFilesFromS3Paths([url], [file.title]);
      }
    },
    async originalDownload(data) {
      let url =
        this.basePdfdownloadUrl +
        data.configurable_document_data.document_upload_url;
      await this.downloadFilesFromS3Paths([url], [data.title]);
    },
    async onDownload(data) {
      console.log("---data", data);
      let doc = data.document_completed_url
        ? data.document_completed_url
        : data.configurable_document_data.document_upload_url;

      this.configureLoading = true;
      this.configureLoadingMessage = "Document(s) Downloading....";
      console.log(doc, data.title);
      /** MIXINS */
      await this.downloadFilesFromS3Paths([doc], [data.title]);
      this.configureLoading = false;
    },
    statusSort(a, b) {
      if (a.document_status < b.document_status) return -1;
      if (a.document_status > b.document_status) return 1;
      return 0;
    },
    titleSort(a, b) {
      if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
      if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
      return 0;
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    createdDateSort: function (a, b) {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
    async getDocumentUserName() {
      if (
        this.userDocuments &&
        this.userDocuments[0] &&
        this.userDocuments[0].document_users &&
        this.$route.params.contact_id
      ) {
        let data = this.userDocuments[0];
        data.document_users.forEach((element) => {
          console.log(element.email);
        });
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchUserDocuments();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchUserDocuments();
      }
    },
    async fetchUserDocuments() {
      this.authid = this.getAuthenticatedUser._id;
      this.userDocuments = [];
      try {
        this.configureLoading = true;
        let params = {
          limit: this.pageSize || 10,
          page: this.page || 1,
          contact_id: this.$route.params.contact_id,
        };

        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.selectedStatus) {
          params.document_status = this.selectedStatus;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        await this.$store.dispatch("documents/fetchUserDocuments", params);
        this.userDocuments = this.getUserDocuments.data || [];
        this.total = this.getUserDocuments.total;
        this.configureLoading = false;
        this.getDocumentUserName();
      } catch (err) {
        this.configureLoading = false;
      }
    },
    onEdit(document) {
      if (document.document_status == "DRAFT") {
        let newWindow = open(
          `/emp-documents/custom/${document._id}/edit`,
          "example",
          "width=1500,height=900"
        );
        newWindow.focus();
        newWindow.onload = function () {};
        // this.$router.push({
        //   name: "employee-documents-custom-document-edit",
        //   params: {
        //     employee_document_id: document._id,
        //   },
        // });
      } else {
        let newWindow = open(
          `/emp-documents/custom/${document._id}`,
          "example",
          "width=1500,height=900"
        );
        newWindow.focus(), (newWindow.onload = function () {});
        // this.$router.push({
        //   name: "employee-documents-custom-document",
        //   params: {
        //     employee_document_id: document._id,
        //   },
        // });
      }
    },
    async loadData(data) {
      if (data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchUserDocuments();
      }
    },

    async onDelete(row) {
      this.$confirm("Are you sure to delete the document?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteDocument(row);
      });
    },

    async permanentDocument(document_id) {
      this.$confirm(
        "Are you sure Want To Permanently Delete.Complete Data Of This Document ?"
      )
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/parmanentdelete", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Deleted Permanently");
            this.$emit("reloadDashboardStats", true);
            this.fetchUserDocuments();
          } else {
            errorNotification("Error in Permanent deleting document");
          }
        })
        .catch(() => {});
    },
    refreshDocument() {
      window.location.reload();
    },
    async archiveDocumentundo(document_id) {
      this.$confirm("Are you sure to Undo the document?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/undoDocument", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Undo successfully");
            //  this.$emit("reloadDashboardStats", true);
            this.fetchUserDocuments();
          } else {
            errorNotification("Error in Undo document");
          }
        })
        .catch(() => {});
    },

    async deleteDocument(row) {
      // console.log(row._id);
      await this.$store.dispatch("documents/archiveDocument", {
        document_id: row._id,
      });
      if (this.getArchiveDocumentStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document deleted successfully",
        });
        this.fetchUserDocuments();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in deleting document",
        });
      }
    },
    goToCreateContact() {
      this.$router.push({ name: "add-contact" });
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    //getContactDeleteStatus
    this.$store.commit("documents/setAllDocuments", null, { root: true });
    this.$store.commit("documents/setRenameDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDuplicateDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setArchiveDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDuplicateDocumentErrors", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentVoidStatus", null, { root: true });
    this.$store.commit("documents/setUserDocuments", null, { root: true });
    this.$store.commit("documents/setarchiveDocuments", null, { root: true });
  },
};
</script>
<style lang="scss" scoped>
.back-btn {
  padding: 12px;
  align-items: center;
  font-family: "Inter", sans-serif;
  padding: 12px;
  align-items: center;
}
.title {
  letter-spacing: 0.019em;
  margin: 0.5em auto;
}
.text-secondary-sent {
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  text-align: left !important;
  color: #439ffc !important;
}
.doc-sub {
  font-size: 10px;
  margin-top: -15px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
}
.doc-subs {
  font-size: 10px;
  margin-top: -13px;
  font-weight: 300;
  text-align: right;
  margin-right: 9%;
}
.doc-title {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.completed::after {
    background: #08ad36;
  }
  &.draft::after {
    background: #f19203;
  }
  &.sent::after {
    background: #2f80ed;
  }
  &.active::after {
    background: #635e5e;
  }
  &.expired::after {
    background: #f03232;
  }
}
.custom-pagination {
  text-align: center;
  margin-top: 5px;
}
.sent {
  font-size: 12px;
  display: flex;
  flex-flow: row wrap;
}
.sent li a {
  margin-left: 3px;
  font-size: 12px;
  text-align: center;
}

.DialogueStyle {
  margin-top: 50px;
}
.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }

  // .icon-block {
  .initial {
    grid-template-columns: 35px auto;
    .icon {
      border: 1px solid #000;
      text-align: center;
      border-radius: 30px;
      padding: 5px;
      background-color: aliceblue;
    }
  }
  // }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #f19203;
    }
    &.sent::after {
      background: #2f80ed;
    }
    &.active::after {
      background: #635e5e;
    }
    &.expired::after {
      background: #f03232;
    }
  }
}
@media (max-width: 991.98px) {
  .select-status {
    // margin-bottom: 10px;
    width: 100% !important;
    margin-left: 0px !important;
    // margin-top: 10px;
  }
  .search-string {
    width: 100% !important;
  }
}
.top-search-condition-bar-type-two {
  display: flex;
  margin-bottom: 5px !important;
  flex-direction: column;
  .top-search-board {
    display: flex;
    h3 {
      line-height: 40px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .actions-wrapper {
    .search-string {
      margin-left: 3px;
    }
  }
}
</style>
